import React, { useEffect } from "react"
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import Link from 'components/link'

import Layout from 'components/templates/Layout'
const SEARCH_URL = `${process.env.GATSBY_ZMS_API_URL}search/all/`;

const SearchPage = (props) => {
	const dispatch = useDispatch();
	const searchTerms = useSelector((state) => state.SearchReducer.searchTerms);
	const page = useSelector((state) => state.SearchReducer.page);
	const searchData = useSelector((state) => state.SearchReducer.searchData);
	const searchLoading = useSelector((state) => state.SearchReducer.searchLoading);
	// const searchFailed = useSelector((state) => state.SearchReducer.searchFailed);
	const { location } = props;
	let types = {
		Neighborhoods: {
			cleanType: 'Neighborhood',
			urlRoot: '/neighborhoods/'
		},
		Event: {
			cleanType: 'Event',
			urlRoot: '/events/'
		},
		Blog: {
			cleanType: 'Article',
			urlRoot: '/blog/'
		},
		ContentEditor: {
			cleanType: 'Page',
			urlRoot: ''
		} ,
		Release: {
			cleanType: 'Press Release',
			urlRoot: '/news/press-release/'
		},
		Accolade: {
			cleanType: 'Accolade',
			urlRoot: ''
		},
		Clipping: {
			cleanType: 'Press Clipping',
			urlRoot: ''
		},
		Story: {
			cleanType: 'Story Inspiration',
			urlRoot: '/news/story/'
		},
		Listing: {
			cleanType: 'Business Listing',
			urlRoot: '/listing/'
		},
	}; 
	let terms = searchTerms;
	if (location.search !== '') {
		let split = location.search.substr(1).split('=');
		split.shift()
		terms = split.join('=');
		terms = decodeURIComponent(terms).replace(/\+/g, ' ');
	}
	
	const pageSize = 10;
	let urlString = `${SEARCH_URL}?terms=${terms}&_page=${page}&_pageSize=${pageSize}&_lang=en`;
	useEffect(() => {
		const retrieveSearch = async () => {
			try {
				const items = await axios.get(urlString);
				dispatch({ type:'SET_SEARCH_DATA', 
					total: items.data.total,
					data: items.data.data,
					pages: items.data.pages,
				});
				dispatch({ type:'SET_SEARCH_TERMS',
					searchTerms: terms,
				});
			} catch (err) {
				dispatch({ type:'SET_SEARCH_FAILURE', 
					failed: true, });
			} finally {
				dispatch({ type:'SET_SEARCH_LOADING', 
					loading: false, });
			}
		};
		retrieveSearch();
	}, [urlString, dispatch, terms]);

	const Pager = (props) => {
		let pages = [];
		if (props.currentPage > 1) { // Show previous button if not on first page
			pages.push(<button key={`page_prev`} className='button' onClick={() => dispatch({ type:'SET_PAGE', page:props.currentPage - 1 })}>&lt; Prev</button>);
		}
		if (props.searchData.pages > 1) {
			// Determine the start and end of the pagination
			let startPage = props.currentPage - 5;
			let endPage = props.currentPage + 4;
			let loopStart = 1;
			let loopEnd = props.searchData.pages;
			if (startPage > 0)loopStart = startPage; // check for positive value to set the start of pagination
			if (startPage > 0 && endPage >= props.searchData.pages) { // check if last page is reached in paginator
				if (startPage >= 10) {
					loopStart = props.searchData.pages - 9;
				} else {
					loopStart = startPage;
				}
				loopEnd = props.searchData.pages;
			} else if (startPage > 0 && endPage < props.searchData.pages) { // otherwise set the last page based off of the current page
				loopEnd = endPage;
			} else if (props.searchData.pages < 10) {
				loopEnd = props.searchData.pages;
			} else if (endPage < 10) {
				loopEnd = 10;
			}
			for (let i=loopStart; i<=loopEnd; i++) { // create the buttons for the pagination
				pages.push(<button className={(i === props.currentPage ? 'current' : '')} key={`page_${i}`} onClick={() => dispatch({ type:'SET_PAGE', page:i })}>{i}</button>);
			}
		}
		if (props.currentPage < props.searchData.pages) { // Show next button if not on last page
			pages.push(<button key={`page_next`} className='button' onClick={() => dispatch({ type:'SET_PAGE', page:props.currentPage + 1 })}>Next &gt;</button>);
		}
		if (pages.length > 0)return (<div className="grid-container"><div className="grid-x"><div className="small-12 cell"><div className="paginator">{pages}</div></div></div></div>);
		return (<div/>);
	}

	function limit(string='',limit=0) {
		return string.split(' ', limit).join(' ')+'...';
	}
    return (
        <Layout>
			<section id="search-hero" className="hero-header-hero">
				<div className="flenter">
					<div className="grid-x title-area">
						<div className="small-12 medium-6 cell">
							<h3>Search Results for "{terms}"</h3>
							{searchData.data && <p>Showing <strong>{!searchData.data.length ? '0' : (page*pageSize - pageSize) + 1} - {(page*pageSize < searchData.total) ? page*pageSize : searchData.total}</strong> out of <strong>{searchData.total}</strong> results.</p> }
						</div>
						<div className="small-12 medium-6 cell" style={{textAlign:'right', display:'inline-block'}}>
							<label className="sr-only" htmlFor="search">Search the Site</label>
							<form action="/search/" className="closed" id="search-form">
								<input id="search" name="keywords" className="closed" type="text" placeholder="Beaches, hotels..." />
								<input className="search-button" type="Submit" defaultValue="Search"/>
							</form>
						</div>
					</div>
					<section id="search-results">
						<div className="grid-x">
							<div className="small-12 cell">
								{searchData.data && searchData.data.map((item, key) => { 
									if (!types.hasOwnProperty(item.model)) item.model = "ContentEditor";
									if (searchLoading) {
										return 'Loading';
									}
									else {
										return (
										<div key={key} className="results-card float-me">
											<Link href={item.uri}>
												<div className="grid-x">
													<div className="small-12 cell">
														<div className="small">{types[item.model]['cleanType']}</div>
														<h5 className="lower">{item.model !== "ContentEditor" ? item.title[0] : item.title}</h5>
														<p>{item.model !== "ContentEditor" ? limit(item.content, 42) : "View Page"}</p>
													</div>
												</div>
											</Link>
										</div>
									)
									}
								})}
							</div>
						</div>
					</section>
				</div>
				{searchData.pages > 0 && 
					<Pager searchData={searchData} currentPage={page} />
				}
			</section>
		</Layout>
    )
}

export default SearchPage

export { Head } from 'components/templates/Head';